import { Component, ViewChild } from '@angular/core';
import { SaleInvoice } from '../sale-invoices/sale-invoice.model';
import { SaleInvoicesService } from '../sale-invoices/sale-invoices.service';
import { SaleInvoiceLine } from '../sale-invoice-lines/sale-invoice-line.model';
import { SaleInvoiceLinesService } from '../sale-invoice-lines/sale-invoice-lines.service';
import { SaleInvoiceHeaderStatus } from '../sale-invoice-header-statuses/sale-invoice-header-status.model';
import { SaleInvoiceHeaderStatusesService } from '../sale-invoice-header-statuses/sale-invoice-header-statuses.service';
import { SaleInvoiceHeaderHeaderStatus } from '../sale-invoice-header-header-statuses/sale-invoice-header-header-status.model';
import { SaleInvoiceHeaderHeaderStatusesService } from '../sale-invoice-header-header-statuses/sale-invoice-header-header-statuses.service';
import { SaleDocumentDetailsComponent } from '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { MailModel } from '../../../connectors/mail/mail.model';
import { MailTemplateTypesService } from '../../../mails/mail-template-types/mail-template-types.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { ThirdContactInformation } from '../../../thirds/thirds/third-contacts/third-contact-informations/third-contact-information.model';
import { DocumentData } from '../../../base/documents/document-datas/document-data.model';
import { DocumentsService } from '../../../base/documents/documents/documents.service';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { DocumentDatasService } from '../../../base/documents/document-datas/document-datas.service';
import { PaymentsService } from '../../../base/payments/payments.service';
import { DocumentRelationsService } from '../../../base/documents/document-relations/document-relation.service';
import { SaleDocumentLineDiscountOperationsService } from '../../sale-documents/sale-document-line-discount-operations/sale-document-line-discount-operations.service';
import { ModificationService } from '../../../core/services/modification.service';
import { SaleCreditNoteHeaderStatusesService } from '../../sale-credit-notes/sale-credit-note-header-statuses/sale-credit-note-header-statuses.service';
import { SaleOrderHeaderStatusesService } from '../../sale-orders/sale-order-header-statuses/sale-order-header-statuses.service';
import { SaleQuoteHeaderStatusesService } from '../../sale-quotes/sale-quote-header-statuses/sale-quote-header-statuses.service';
import { SaleContractHeaderStatusesService } from '../../sale-contracts/sale-contract-header-statuses/sale-contract-header-statuses.service';
import { SyslinkToolbarActionButton, SyslinkToolbarActionMenu, SyslinkToolbarFileButton, ToolbarComponent } from 'projects/libraries/syslink-components/src/public-api';
import { DomSanitizer } from '@angular/platform-browser';
import { from, Observable, mergeMap,  switchMap } from 'rxjs';
import { FileData } from '../../../connectors/filesystem/file-data';
import { DocumentLineRelationsService } from '../../../base/documents/document-line-relations/document-relation.service';


@Component({
  selector: 'app-sale-invoice-details',
  templateUrl: '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component.html',
  styleUrls: ['./sale-invoice-details.component.scss']
})
export class SaleInvoiceDetailsComponent extends SaleDocumentDetailsComponent<
  SaleInvoice,
  SaleInvoicesService,
  SaleInvoiceLine,
  SaleInvoiceLinesService,
  SaleInvoiceHeaderStatus,
  SaleInvoiceHeaderStatusesService,
  SaleInvoiceHeaderHeaderStatus,
  SaleInvoiceHeaderHeaderStatusesService
> {

  constructor(
    public override saleQuoteHeaderStatusesService: SaleQuoteHeaderStatusesService,
    public override saleOrderHeaderStatusesService: SaleOrderHeaderStatusesService,
    public override saleInvoiceHeaderStatusesService: SaleInvoiceHeaderStatusesService,
    public override saleCreditNoteHeaderStatusesService: SaleCreditNoteHeaderStatusesService,
    public override saleContractHeaderStatusesService: SaleContractHeaderStatusesService,
    public override ngxUiLoaderService: NgxUiLoaderService,
    public override activatedRoute: ActivatedRoute,
    public override reportsService: ReportsService,
    private mailTemplateTypesService: MailTemplateTypesService,
    public override documentService: DocumentsService,
    public override thirdsService: ThirdsService,
    public override documentDatasService: DocumentDatasService,
    public override paymentsService: PaymentsService,
    public override documentRelationsService: DocumentRelationsService,
    public override saleDocumentLineDiscountOperationsService: SaleDocumentLineDiscountOperationsService,
    public override documentLineRelationsService: DocumentLineRelationsService,
    public override modificationService: ModificationService,
    private saleInvoiceLinesService: SaleInvoiceLinesService,
    public override sanitizer: DomSanitizer
  ) {
    super(saleQuoteHeaderStatusesService, saleOrderHeaderStatusesService, saleInvoiceHeaderStatusesService, saleCreditNoteHeaderStatusesService, saleContractHeaderStatusesService, ngxUiLoaderService, activatedRoute, reportsService, documentDatasService, thirdsService, paymentsService, documentRelationsService, saleDocumentLineDiscountOperationsService, documentService, documentLineRelationsService, modificationService, sanitizer);
  }

  private latestReminderNumberSelected: number | null = null;

  protected override loadDependencies() {

    this.saleDocumentService = AppInjectorService.injector.get(SaleInvoicesService);
    this.saleDocumentLinesService = AppInjectorService.injector.get(SaleInvoiceLinesService);
    this.saleDocumentStatusesService = AppInjectorService.injector.get(SaleInvoiceHeaderStatusesService);
    this.saleDocumentHeaderStatusesService = AppInjectorService.injector.get(SaleInvoiceHeaderHeaderStatusesService);

    // const taxesService = AppInjectorService.injector.get(TaxesService);
    // const accountsService = AppInjectorService.injector.get(AccountsService);
    // const translateService = AppInjectorService.injector.get(TranslateService);

    this.documentType = 'SaleInvoice';
    this.subModuleCode = 'Sales.Invoice';
    this.defaultStatusCode = 'Invoice.Proforma';
    this.documentLinesColumns = [...this.saleDocumentService.getDefaultDocumentLineColumns(this.subModuleCode, this.documentType),
      // new SyslinkColumn({ order: 15, field: 'TaxId', visible: false, label: translateService.instant('Tax'), type: 'string', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: taxesService, displayKey: 'Alias', filter: "IsVisible eq true" }, width: 70 }),
      // new SyslinkColumn({ order: 15, field: 'AccountId', visible: false, label: translateService.instant('Account'), type: 'string', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: accountsService, displayKey: 'Name' }, width: 70 }),
    ];

    // this.getFormattedTitle((this.element?.No) ?? undefined);
  }

  @ViewChild('toolbar') toolbar!: ToolbarComponent;

  public override async updateToolbar() {
    this.toolbarActions = [];
    this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'save', icon: 'save', text: this.translateService.instant('Save'), onClick: async () => { await this.update() }, hotkey: 'control.s', visible: this.authService.hasPermission(this.newBasePermissionKey + '.update') }));
    this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'preview', icon: 'print', text: this.translateService.instant('Preview'), onClick: () => { this.onPreviewModalButtonClicked() }, visible: this.authService.hasPermission(this.newBasePermissionKey + '.preview') }));
    // this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'preview', icon: 'print', text: this.translateService.instant('Preview'), onClick: () => { this.onPreviewButtonClicked() }, hotkey: 'control.p', visible: this.authService.hasPermission(this.newBasePermissionKey + '.preview') }));

    if (this.element.Id) {
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'previewWorkTime', icon: 'clock', text: this.translateService.instant('Preview worktime'), onClick: () => { this.onPreviewWorkTimeButtonClicked() }, hotkey: 'control.p', visible: await this.showPreviewWorkTime() }));
      this.toolbarActions.push(new SyslinkToolbarFileButton({ entityType: 'SaleInvoiceHeader', entityId: this.element.Id }));
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'email', icon: 'email', text: this.translateService.instant('Send mail'), onClick: () => { this.onSendMailButtonClicked() }, visible: this.authService.hasPermission(this.basePermissionKey + '.sendMail') }));
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'delete', icon: 'trash', text: this.translateService.instant('Delete'), onClick: () => { this.onDeleteBtnClicked() }, visible: this.authService.hasPermission(this.basePermissionKey + '.delete') }));
      this.toolbarActions.push(new SyslinkToolbarActionMenu({
        items: [
          {
            icon: 'fa-solid fa-copy', items: [
              {
                // Sales
                // -----
                icon: 'fa-solid fa-euro-sign', text: this.translateService.instant('Sale'), items: [
                  { text: this.translateService.instant('Transform to Quote'), onClick: () => { this.transform('Sale', 'SaleQuote'); }, visible: this.documentType !== 'SaleQuote' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleQuote') },
                  { text: this.translateService.instant('Transform to Order'), onClick: () => { this.transform('Sale', 'SaleOrder'); }, visible: this.documentType !== 'SaleOrder' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleOrder') },
                  { text: this.translateService.instant('Transform to Invoice'), onClick: () => { this.transform('Sale', 'SaleInvoice'); }, visible: this.documentType !== 'SaleInvoice' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleInvoice') },
                  { text: this.translateService.instant('Transform to Credit note'), onClick: () => { this.transform('Sale', 'SaleCreditNote'); }, visible: this.documentType !== 'SaleCreditNote' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleCreditNote') },
                  { text: this.translateService.instant('Transform to Contract'), onClick: () => { this.transform('Sale', 'SaleContract'); }, visible: this.documentType !== 'SaleContract' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleContract') }
                ]
              },
              //           {
              //             // Purchases
              //             // ---------
              //             icon: 'fa-solid fa-cart-shopping', text: 'Purchase', items: [
              //               { text: 'Transform to Order', onClick: () => { this.transform('Purchase', 'PurchaseOrder'); }, visible: this.documentType !== 'PurchaseOrder' && this.authService.hasPermission(this.basePermissionKey + '.transformToPurchaseOrder') },
              //               { text: 'Transform to Invoice', onClick: () => { this.transform('Purchase', 'PurchaseInvoice'); }, visible: this.documentType !== 'PurchaseInvoice' && this.authService.hasPermission(this.basePermissionKey + '.transformToPurchaseInvoice') },
              //               { text: 'Transform to Credit note', onClick: () => { this.transform('Purchase', 'PurchaseCreditNote'); }, visible: this.documentType !== 'PurchaseCreditNote' && this.authService.hasPermission(this.basePermissionKey + '.transformToPurchaseCreditNote') },
              //             ]
              //           },
              //           {
              //             // Stocks
              //             // -----
              //             icon: 'fa-solid fa-warehouse', text: 'Stock', items: [
              //               { text: 'Transform to Customerincomings', onClick: () => { this.transform('StockMove', 'StockMoveCustomerIncoming'); }, visible: this.documentType !== 'StockMoveCustomerIncoming' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveCustomerIncoming') },
              //               { text: 'Transform to Customeroutgoings', onClick: () => { this.transform('StockMove', 'StockMoveCustomerOutgoing'); }, visible: this.documentType !== 'StockMoveCustomerOutgoing' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveCustomerOutgoing') },
              //               { text: 'Transform to Supplieroutgoings', onClick: () => { this.transform('StockMove', 'StockMoveSupplierOutgoing'); }, visible: this.documentType !== 'StockMoveSupplierOutgoing' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveSupplierOutgoing') },
              //               { text: 'Transform to Supplierincomings', onClick: () => { this.transform('StockMove', 'StockMoveSupplierIncoming'); }, visible: this.documentType !== 'StockMoveSupplierIncoming' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveSupplierIncoming') },
              //               { text: 'Transform to StockMoveInternal', onClick: () => { this.transform('StockMove', 'StockMoveInternal'); }, visible: this.documentType !== 'StockMoveInternal' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveInternal') },
              //             ]
              //           },
              {
                // Copy
                // ----
                beginGroup: true, text: this.translateService.instant('Copy'), visible: this.authService.hasPermission(this.basePermissionKey + '.copy'), onClick: () => {
                  this.documentType.includes("Sale") || this.documentType.includes("Customer") ? this.updatecopyModalFilter("customer") : this.updatecopyModalFilter("supplier");
                  if (!this.copyModal) return;
                  this.copyModal.open({
                    subject: this.element.Subject,
                    showThird: this.documentType.includes("StockMoveInternal") ? false : true,
                    showSubject: this.documentType.includes("Stock") ? false : true,
                    showReference: true,
                    reference: this.element.ExternalReference,
                    title: "Copy",
                    showSavingMessage: this.modificationService.hasModifications ? 'The document will be saved' : undefined,
                    thirdFilter: this.copyModalFilter,
                    selectedKey: this.selectedKey
                  });
                }
              },
              {
                // New version
                // -----------
                text: this.translateService.instant('New version'), visible: this.authService.hasPermission(this.basePermissionKey + '.transformTo' + this.documentType), onClick: () => {
                  let mainReportType;
                  let subReportType;

                  if (this.documentType.includes('Sale')) { mainReportType = 'Sale'; }
                  else if (this.documentType.includes('Purchase')) { mainReportType = 'Purchase'; }
                  else if (this.documentType.includes('StockMove')) { mainReportType = 'StockMove'; }
                  else { return; }
                  subReportType = this.documentType.replace(mainReportType, '');

                  this.transform(mainReportType, mainReportType + subReportType);
                }
              }
            ]
          }
        ]
      }));
    }
    this.afterUpdateToolbar();
    this.toolbar.ngOnInit();
  }

  public override canEditDocument(): boolean {
    return !this.hasActiveStatus('Invoice.Ventilated') && !this.hasActiveStatus('Invoice.Canceled');
  }

  public async showPreviewWorkTime(): Promise<boolean> {
    if (!this.element.Id) return false;
    if (!this.authService.hasPermission(this.newBasePermissionKey + '.preview')) return false;
    let lines = await this.saleInvoiceLinesService.load({ filter: ['HeaderId.Id eq ' + this.element.Id + " and TimeManagementLinked gt 0"], select: ['Id'], expand: [] });
    if (lines.length <= 0) return false;
    return true;
  }

  // Mail
  // ----
  public override async onSendMailButtonClicked() {

    if (this.modificationService.hasModifications || !this.element.Report || (await this.showPreviewWorkTime() == true && !this.element.WorkTimeReport)) {
      if (!this.saveConfirmModel) return;
      this.saveConfirmModel.content = 'Please confirm saving and generate report before send mail';
      this.saveConfirmModel.open({ event: 'SendMail' });
    }
    else {

      this.mailModel = undefined;
      setTimeout(async () => {
        this.mailModel = new MailModel();

        this.mailTemplateType = (await this.mailTemplateTypesService.load({ filter: ["Code eq 'SaleInvoice'"] }))[0];

        this.element.DocumentDataCollection.forEach((d: DocumentData) => {
          if ((d.HasPrincipal || d.HasSecondary) && this.mailModel) {
            this.mailModel.ToThirdContactInformationId.push(...d.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForInvoice == true));
          }
          if (d.HasCC && this.mailModel) {
            this.mailModel.CCThirdContactInformationId.push(...d.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForInvoice == true));
          }
          if (d.HasCCI && this.mailModel) {
            this.mailModel.CCIThirdContactInformationId.push(...d.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForInvoice == true));
          }
        })

        this.mailModel.ReportType = this.documentType;
        this.mailModel.HeaderId = this.element.Id?.toString() ?? "0";
        this.mailModel.FileName = this.reportsService.updateFileName(this.documentType, this.element.No + ".pdf");
        if (!this.emailModal) return;
        this.emailModal.mailTemplateType = this.mailTemplateType;
        await this.emailModal.loadMailTemplateData();

        await this.emailModal?.open();
      }, 50);
    }
  }

  protected override afterUpdateToolbar(): void {
    this.toolbarActions.push(
      new SyslinkToolbarActionMenu({
        items: [
          {
            icon: 'fa-solid fa-business-time', items: [
              { text: this.translateService.instant('First reminder'), visible: !!this.element?.Id, onClick: () => { this.showReminder(1) } },
              { text: this.translateService.instant('Second reminder'), visible: !!this.element?.Id, onClick: () => { this.showReminder(2) } },
              { text: this.translateService.instant('Third reminder'), visible: !!this.element?.Id, onClick: () => { this.showReminder(3) } },
            ]
          }
        ]
      })
    );
  }

  // Preview
  // -------
  public override fileDataLoadSuccess(fileData: FileData, preview: boolean = true, stopLoader: boolean = true) {
    this.previewData = this.sanitizer.bypassSecurityTrustResourceUrl(fileData.fileContent + '#');
    from(this.saleDocumentService.findByID(this.element.Id!)).subscribe({
      next: (remoteInvoice) => {
        this.element.Report = remoteInvoice.Report;
        this.element.FirstReminder = remoteInvoice.FirstReminder;
        this.element.SecondReminder = remoteInvoice.SecondReminder;
        this.element.ThirdReminder = remoteInvoice.ThirdReminder;
        this.element.WorkTimeReport = remoteInvoice.WorkTimeReport;
        if (preview) {
          this.previewId = fileData.fileName;
          this.previewModal.open();
        }
        if (stopLoader)
          this.ngxUiLoaderService.stop();
      }
    });
  }

  private showReminder(number: number) {
    this.latestReminderNumberSelected = number;
    this.ngxUiLoaderService.start();
    this.previewDocumentType = "Reminder_" + number;
    this.saleDocumentService.getReminder(new SaleInvoice(this.element), number)
      .pipe(
        switchMap((file) => this.switchMapToFileData(file))
      )
      .subscribe({
        next: fileData => {
          this.fileDataLoadSuccess(fileData);
        },
        error: _ => this.ngxUiLoaderService.stop()
      })
  }

  private regenerateReminder() {
    if (this.latestReminderNumberSelected == null) return;
    this.ngxUiLoaderService.start();
    this.saleDocumentService.regenerateReminder(new SaleInvoice(this.element), this.latestReminderNumberSelected)
      .pipe(
        switchMap((file) => this.switchMapToFileData(file))
      )
      .subscribe({
        next: fileData => {
          this.fileDataLoadSuccess(fileData);
        },
        error: _ => this.ngxUiLoaderService.stop()
      });
  }


  protected override onRegeneratePreviewButtonClicked(event?: Event): void {
    if (this.previewDocumentType == "Report") {
      this.element.Report = null;
      this.onPreviewModalButtonClicked();
    }
    if (this.previewDocumentType == "WorkTime") {
      this.element.WorkTimeReport = null;
      this.onPreviewWorkTimeButtonClicked();
    }
    else {
      this.regenerateReminder();
    }
  }

  public override async onConfirmeSavingBeforeEvent(e: { event: 'Preview' | 'PreviewWorkTime' | 'Transform' | 'SendMail', type: string, totype: string }) {
    await this.update();
    this.saveConfirmModel?.close();
    setTimeout(async () => {
      AppInjectorService.config.setModificationGuard(false);
      switch (e.event) {
        case "Preview":
          this.onPreviewModalButtonClicked();
          break;
        case "PreviewWorkTime":
          this.onPreviewWorkTimeButtonClicked();
          break;
        case "Transform":
          this.transform(e.type, e.totype);
          break;
        case "SendMail":
          this.ngxUiLoaderService.start();
          await this.generateReportBeforeSendMail();
          this.saveConfirmModel?.close();
          break;
      }
    }, 100);
  }

  private async generateReportBeforeSendMail() {
    let fetchWorkTimeReport = await this.showPreviewWorkTime();
    if (!this.element.Report && fetchWorkTimeReport == true && !this.element.WorkTimeReport) {// Generate invoice report and worktime report
      const observable1 = from(this.onPromiseLoadReport());

      observable1.pipe(
        mergeMap(result1 => {
          return from(this.onPromiseLoadWorkTimeReport());
        })
      ).subscribe({
        complete: () => {
          AppInjectorService.config.setModificationGuard(false);
          this.onSendMailButtonClickedWithTimeout();
          this.ngxUiLoaderService.stop();
        }
      });
    }
    else if (!this.element.Report && (fetchWorkTimeReport == false || this.element.WorkTimeReport)) { // Generate invoice report
      const observable1 = from(this.onPromiseLoadReport());
      observable1.pipe().subscribe({
        next: (result) => {
          this.onSendMailButtonClickedWithTimeout();
        },
        complete: () => {
          AppInjectorService.config.setModificationGuard(false);
          this.ngxUiLoaderService.stop();
        }
      });
    }
    else if (this.element.Report && fetchWorkTimeReport == true && !this.element.WorkTimeReport) { // Generate workTimereport report
      const observable1 = from(this.onPromiseLoadWorkTimeReport());
      observable1.pipe().subscribe({
        next: (result) => {
          this.onSendMailButtonClickedWithTimeout();
        },
        complete: () => {
          AppInjectorService.config.setModificationGuard(false);
          this.ngxUiLoaderService.stop();
        }
      });
    }
    else {// Open mail modal
      AppInjectorService.config.setModificationGuard(false);
      this.onSendMailButtonClickedWithTimeout();
      this.ngxUiLoaderService.stop();
    }
  }

  private async onPromiseLoadReport() {
    return new Promise((resolve, reject) => {
      this.documentService.getReport(this.documentUrlController, this.documentType, this.element)?.pipe(switchMap((file) => this.switchMapToFileData(file))
      )
        .subscribe({
          next: fileData => {
            this.fileDataLoadSuccess(fileData, false, false);
            resolve(fileData);
          },
          error: err => {
            this.ngxUiLoaderService.stop();
            reject(err);
          }
        })
    });
  }

  private onPromiseLoadWorkTimeReport() {
    return new Promise((resolve, reject) => {
      this.documentService.getWorkTimeReportByInvoiceId(this.element)?.pipe(switchMap((file) => this.switchMapToFileData(file))
      )
        .subscribe({
          next: fileData => {
            this.fileDataLoadSuccess(fileData, false, false);
            resolve(fileData);
          },
          error: err => {
            this.ngxUiLoaderService.stop();
            reject(err);
          }
        })
    });
  }
  private onSendMailButtonClickedWithTimeout() {
    // TODO check best method
    setTimeout(() => {
      this.onSendMailButtonClicked();
    }, 500);
  }
  //-------------------------------------------------------------------------
}
